import { createApp } from 'vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'

const app = createApp(App) // 创建实例

app.config.warnHandler = () => null

app.use(router)
app.use(ElementPlus)

app.mount('#app');