<template>
    <router-view class="router-view" v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
li{
  list-style-type:none;
}
html, body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: Helvetica, Arial, "Hiragino Sans GB", "Microsoft Yahei", "\5FAE\8F6F\96C5\9ED1", STHeiti, "\534E\6587\7EC6\9ED1", sans-serif;
}
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    min-height: 25px;
    min-width: 25px;
    border: 1px solid #e0e0e0
}

::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border: 1px solid #efefef
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    overflow: auto
}
</style>
